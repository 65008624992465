import React, { useState, useEffect } from "react"
import { isLoggedIn } from "../../../services/auth"

const FooterNavMobile = () => {
    const [logged, setLogged] = useState(false)
    useEffect(() => {
        const isLogged = isLoggedIn()
        setLogged(isLogged)
    }, []);
  return (
    <div className={`d-sm-none footer-mobile-navigator${!logged ? ' d-none' : ''}`}>
      <div className="content">
        <div className="item">
          <a href="/" className="mobile-nav-btn">
            <img src="/img/home.svg" alt="Home"/>
          </a>
        </div>
        <div className="item">
          <a href="/explore/" className="mobile-nav-btn">
            <img src="/img/search.svg" alt="Search"/>
          </a>
        </div>
        <div className="item">
          <a href="/account/my-boards/" className="mobile-nav-btn">
            <img src="/img/boards.svg" alt="Boards"/>
          </a>
        </div>
        <div className="item">
          <a href="/account/" className="mobile-nav-btn">
            <img src="/img/account.svg" alt="Account"/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default FooterNavMobile
