import React, { useState, useEffect, useCallback } from "react"
import { Container } from 'react-bootstrap'
import { handleLogin, isLoggedIn, getUser } from "../../../services/auth"
import PropTypes from "prop-types"
import MainSidebar from "./MainSidebar"
import ExploreHeader from "./ExploreHeader"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

const Header = ({ pageData, siteName }) => {
  const [logged, setLogged] = useState(false)
  const [scroll, setScroll] = useState(false)
  const [show, setShow] = useState(false)

  const toggleSidebar = useCallback(() => setShow(value => !value));
  const [_pageData, _setPageData] = useState({
    pageClass: "",
    bodyClass: "",
    pageTitle: "",
  })
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 75);
    });
    if (typeof pageData !== 'undefined') {
      _setPageData(pageData);
    }
    const isLogged = isLoggedIn()
    setLogged(isLogged)
  }, []);
  var metaTags = null;
  if (_pageData.metaTags && _pageData.metaTags !== null && typeof _pageData.metaTags !== 'undefined') {
    metaTags = JSON.parse(_pageData.metaTags)
  }
  const Member = getUser()
  return (
    <>
      {metaTags && (
        <Helmet>
          <title>{metaTags.Title}</title>
          <meta name="title" content={metaTags.MetaTitle ? metaTags.MetaTitle : metaTags.Title} />
          {metaTags.MetaKeywords && (<meta name="keywords" content={metaTags.MetaKeywords} />)}
          <meta name="description" content={metaTags.MetaDescription} />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <link rel="canonical" href={metaTags.URL} />
          <meta property="og:locale" content="en_AU" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={metaTags.Title} />
          {metaTags.MetaDescription && (<meta property="og:description" content={metaTags.MetaDescription} />)}
          <meta property="og:url" content={metaTags.URL} />
          <meta property="og:site_name" content={metaTags.SiteName} />
        </Helmet>
      )}
      {_pageData.pageClass == 'HomePage' &&
        <ExploreHeader data={_pageData} />
      }
      <div className={`main-header${_pageData.pageClass == 'HomePage' ? ' explore-header' : ''}`}>
        <nav className={`mNav${scroll ? " scrolled" : ""}`}>
          <Container>
            <div className="mNav-inner">
              <div className={`mNav-item mNav-hamburger${show ? " active" : ""}`}
                onClick={toggleSidebar}
              >
                <div className="mNav-hamburger-inner"></div>
                <span>Menu</span>
              </div>

              <a href="/" className="mNav-logo">
                <img
                  src="/img/account/birdy-logo.svg"
                  alt="logo"
                  className="img-fluid"
                />
              </a>
              <ul className={`mNav-list${!logged ? ' d-none' : ''}`}>
                {!logged ? (
                  <li>
                    <Link className="mNav-item" to="/login/">
                      <svg width='25' height='25'>
                        <use xlinkHref='/img/icons.svg#icon-tribeca-user'></use>
                      </svg>
                      <span>login</span>
                    </Link>
                  </li>
                ):(
                    <>
                    <li>
                      <Link className="mNav-item" to="/account/">
                        <svg width='25' height='25'>
                          <use xlinkHref='/img/icons.svg#icon-tribeca-user'></use>
                        </svg>
                        <span>{Member.FirstName}</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="mNav-item" to="/account/my-boards/">
                        <svg width='25' height='25'>
                          <use xlinkHref='/img/icons.svg#icon-tribeca-board'></use>
                        </svg>
                        <span>boards</span>
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>

          </Container>

        </nav>

        <MainSidebar show={show} handleClose={setShow}></MainSidebar>
      </div>
    </>
  )
}

Header.propTypes = {
  siteName: PropTypes.string,
}

Header.defaultProps = {
  siteName: ``,
}

export default Header
